import React from "react";
import { AboutSection } from "./onboarding/AboutSection";
import { AccessSection } from "./onboarding/AccessSection";
import { PrivacySection } from "./onboarding/PrivacySection";
import { TermsSection } from "./onboarding/TermsSection";

export const AgreementPDF = () => {
  return (
    <>
      <AboutSection withPadding={false} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <AccessSection withPadding={false} includeNextPageShort={false} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <PrivacySection withPadding={false} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <TermsSection withPadding={false} />
    </>
  );
};
