import React from "react";
import { Route, Switch } from "react-router-dom";
import { useEffect, useState } from "react";

import { After } from "./features/view/general/after/After";
import { Choice, Confirmation, Mobile } from "./features/objective";
import { getContent } from "./features/contentSlice";
import { doJwtRefresh, keepRefreshingJwt } from "./features/authSlice";
import { GiftCard } from "./features/giftCard/GiftCard";
import { Help } from "./features/view/general/help/Help";
import { Login, Settings } from "./features/view/general";
import { NotFound } from "./features/404/NotFound";
import { OnBoardingComplete } from "./features/milestone/OnboardingComplete";
import { Section } from "./types";
import { Splash } from "./features/splash/Splash";
import { Support } from "./features/view/general/support/Support";
import { SurveyComplete } from "./features/milestone/SurveyComplete";
import { Techniques } from "./features/view/general/techniques/Techniques";
import { useDispatch, useSelector } from "./hooks";
import { View } from "./features/view/View";
import { Wizard } from "./features/common/wizard/Wizard";
import { AgreementPDF } from "./features/view/general/AgreementPDF";

export const App = () => {
  const dispatch = useDispatch(),
    isLoggedIn = useSelector((state) => state.auth.user.id) !== null,
    [ready, setReady] = useState(false),
    steps = [Choice, Confirmation, Mobile, OnBoardingComplete],
    general = <View as="general" />,
    chapters = <View as={Section.CHAPTERS} />,
    anySection = `/(${Object.values(Section).join("|")})`,
    hasRegistered = false; // TODO: Replace hardcoded value

  useEffect(() => {
    if (isLoggedIn) dispatch(keepRefreshingJwt());
    else dispatch(doJwtRefresh()).then(() => setReady(true));
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    dispatch(getContent({ name: Section.PAGE }));
    dispatch(getContent({ name: Section.CHAPTERS }));
    dispatch(getContent({ name: Section.GENERAL }));
  }, [dispatch]);

  return ready ? (
    <div className="app">
      <Switch>
        <Route exact path="/">
          {isLoggedIn ? (
            chapters
          ) : localStorage.getItem("seenSplash") === null ? (
            <Splash />
          ) : (
            general
          )}
        </Route>
        <Route path="/(login|oauth_callback)" component={Login} />
        <Route path="/objective">
          {hasRegistered ? (
            chapters
          ) : (
            <Wizard {...{ steps, exitConfig: { path: Section.CHAPTERS } }} />
          )}
        </Route>
        <Route path="/(demo|chapters)">{isLoggedIn ? chapters : general}</Route>
        <Route path="/home">{isLoggedIn ? chapters : general}</Route>
        <Route path="/giftcard">
          {isLoggedIn ? <GiftCard compact={false} /> : general}
        </Route>
        <Route path={anySection} component={View} />
        <Route path="/survey-complete/:id" component={SurveyComplete} />
        <Route path="/settings" component={Settings} />
        <Route path="/techniques" component={Techniques} />
        <Route path="/after" component={After} />
        <Route path="/help" component={Help} />
        <Route path="/support" component={Support} />
        <Route path="/exit" component={Splash} />
        <Route path="/agreement-pdf" component={AgreementPDF} />
        <Route path="/" component={NotFound} />
      </Switch>
    </div>
  ) : null;
};
